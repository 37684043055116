import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { format } from 'date-fns'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const BlogPostTemplate = ({ data, pageContext = {} }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  const image = getImage(post.frontmatter.image)

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        ogImage={
          post.frontmatter.image &&
          post.frontmatter.image.childImageSharp.gatsbyImageData.images.fallback
            .src
        }
      />
      {post.frontmatter.image && (
        <div className="mt-10 xl:max-w-7xl mx-4 xl:mx-auto block">
          <GatsbyImage
            className="w-full"
            image={image}
            alt={post.frontmatter.title}
          />
        </div>
      )}
      <article className="max-w-4xl mx-4 xl:m-auto prose md:prose-lg dark:prose-light">
        <header className="mt-20 mb-10 dark:text-white">
          <h2 className="font-bold tracking-tight">{post.frontmatter.title}</h2>
          <p className="text-lg">
            {format(new Date(post.frontmatter.date), 'dd.MM.yyyy')}
          </p>
        </header>
        <section
          className="max-w-none"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <nav className="mt-20 dark:text-white">
          <hr />
          <div className="mt-2 flex justify-between">
            <div>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </div>
            <div className="text-right">
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </div>
          </div>
        </nav>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`
